<template>
  <div class="news-detail-page">
    <div class="banner-wrap">
      <img src="@/image/news/banner.png" />
    </div>
    <!--导航条-->
    <navigation-bar pageName="新闻动态" businsessName="详情"></navigation-bar>

    <div class="news-detail-body">
      <div class="title">{{newsDetail.cmsTitle}}</div>
      <div class="date">Date:{{newsDetail.publishTime.substring(0,10)}}</div>
      <div class="news-detail" v-html="newsDetail.content"></div>
    </div>
  </div>
</template>

<script>
  import NavigationBar from '@/components/navigation-bar.vue'
  import {
    getNewsDetail
  } from '@/api/news.js'

  export default {
    components: {
      NavigationBar,
    },
    data() {
      return {
        newsId: null,
        newsDetail: {
          basicDateTime: ''
        }
      }
    },
    created() {
      this.newsId = this.$route.query.newsId
      this.getNewsDetail()
    },
    methods: {
      getNewsDetail() {
        getNewsDetail(this.newsId).then(data => {
          this.newsDetail = data.data
        })
      },
    }
  }
</script>

<style lang="scss">
  .news-detail-page {

  }

  .news-detail-body {
    padding-top: 30px;
    padding: 10px 12px 30px 12px;

    .title {
      font-size: 19px;
      color: #333333;
      line-height: 30px;
      text-align: center;
    }

    .date {
      font-size: 11px;
      color: #999999;
      line-height: 20px;
      text-align: center;
    }

    .news-detail {
      font-size: 13px !important;
      color: #333333;
      line-height: 20px;

      span {
        font-size: 13px !important;
      }

      img {
        max-width: 100% !important;
      }
    }
  }
</style>
